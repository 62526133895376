body,
html {
  height: 100%
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Misans';
  background-color: #f0f0f0;
}

#root {
  height: 100%;
}
