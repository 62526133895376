.app {
  text-align: center;
  min-height: 100%;
  flex-direction: column;
}

.app,
header {
  display: flex;
}

header {
  /* background-image: linear-gradient(135deg, #9cc8f8 0%, #fa8ce4 100%); */
  background-color: rgba(255, 255, 255, 0.897);
  margin: 0px;
  box-shadow: 0 0 0px #888888;
  border-radius: 0px;
  justify-content: center;
}

main {
  padding-bottom: 30px;
}

/* footer {
  width: 100%;
  bottom: 0;
  position: fixed;
  padding: 0.5em 0;
  background-color: #ffffffb3;
  z-index: 11;
} */

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b8b8b8;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: #b8b8b8;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #b8b8b8;
  opacity: 1;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #b8b8b8;
  opacity: 1;
}

.center {
  /* flex: 1; */
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.right {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10 auto;
}

.logo {
  color: black;
  font-size: 40px;
  font-weight: bold;
  /* margin-top: 15px; */
}

.description {
  color: black;
  font-size: 18px;
  margin-bottom: 12px;
}
.description span {
  display: block;
}

.wechat {
  font-size: medium;
  display: flex;
  flex-direction: grow;
  justify-content: center;
  cursor:pointer;
  margin-top: 12px;

}

.wechat a {
  padding-left: 4px;
}

.wechat a:hover {
  color: #22b5ff;
}

.modal {
  border: 1px;
  border-color: #efefef;
  box-shadow: #22b5ff;

}

.copyrights {
  color: rgb(109, 109, 109);
  font-size: 14px;
}

.ant-checkbox-input {
  color: black;
  margin: 4px 0;
}

.ant-checkbox-input label {
  margin: 0 8px
}

label {
  display: inline-block;
  cursor: pointer;
}

.ant-checkbox-input input[type=checkbox] {
  width: 15px;
  height: 13.6px;
  margin-right: 5px;
}

.parent {
  /* display: flex;
  flex-wrap: wrap; */
  margin-top: 20px;
}

.ant-checkbox-input {
  width: 100%;
  max-width: 500px;
  text-align: left;
}

/* .options input[type=checkbox]::before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  text-align: center;
  font-size: 15px;
  background-color: white;
  border-radius: 2.8px;
  border: 1px solid black;
}

.options input[type=checkbox]:checked::before {
  position: absolute;
  background-color: #22b5ff;
  width: 13px;
  height: 13px;
  text-align: center;
  font-size: 15px;
  border: solid 1px black;
  border-radius: 2.8px;
} */

@supports(-webkit-touch-callout: none) {
  .options input[type=checkbox] {
    width: 16px;
    height: 16px;
  }

  .options input[type=checkbox]::before {
    width: 16px;
    height: 16px;
  }

  .options input[type=checkbox]:checked::before {
    width: 14px;
    height: 14px;
  }
}

.search {
  /* height: 45px;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 35px;
  margin-top: 20px; */
  margin-top: 20px;
  display: flex;
}

.search-input {
  /* height: 45px;
  width: 20px;
  flex: 1;
  padding: 0 10px;
  border: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0px;
  font-size: 15px;
  outline: none; */
  padding: 0 10px;
  border: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 0px;
  border: 1px;
  font-size: 15px;
  outline: none;
  height: 45px;
  width: 80%;
  margin-bottom: 20px;
  display: flex;
  background-color: #efefef;
}

.search-button {
  /* height: 45px;
  width: 48px;
  display: flex;
  border: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #22b5ff;
  align-items: center;
  transition: background-color 0.35s; */
  height: 45px;
  width: 60px;
  display: flex;
  border: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #22b5ff;
  align-items: center;
  justify-content: center;
  transition: background-color 0.35s;
  
}

.search-button:hover {
  background: #2a7efb;
}

.search-icon {
  height: 30px;
  width: 30px;
  flex: 1;
}

.results {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media screen and (max-width: 768px) {
  header {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .options label {
    margin: 0 4px;
  }
}

@media screen and (max-width: 360px) {
  header {
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    border-radius: 0px;
  }

  .search {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 30px;
  }

  .options label {
    margin: 0 4px;
  }
}

@media (max-width: 768px) {
  .center {
    flex-direction: column;
    align-items: center;
  }
  .left {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .search {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

/* * 弹窗样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.qr-code {
  max-width: 250px;
  margin: 20px 0;
}

.group-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.validity {
  font-size: 0.9em;
  color: #666;
  margin-bottom: 10px;
}

.note {
  font-size: 0.9em;
  color: #333;
}